<script setup>
// 导入
import { ref, onBeforeUnmount, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatNumberWithCommas } from '@/assets/tools/CommonTool';
import { getHeaderImg } from '@/assets/tools/avatar.js';

const { t } = useI18n();
// 传参
const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
// 属性
let countdownInterval = null;
const countdownData = ref([]);
// 计算属性
// 生命周期
watchEffect(() => {
  if (props.info && props.info.end_time_diff > 0) {
    countdownTimer(props.info.end_time_diff);
  }
});
onBeforeUnmount(() => {
  clearInterval(countdownInterval);
  countdownInterval = null
});
// 方法

function countdownTimer(duration) {
  if (!countdownInterval) {
    countdownInterval = setInterval(function () {
      duration--;
      if (duration < 0) {
        clearInterval(countdownInterval);
        console.log('倒计时结束！');
      } else {
        countdownData.value[0] = Math.floor(duration / 3600);
        countdownData.value[1] = Math.floor((duration % 3600) / 60);
        countdownData.value[2] = duration % 60;
        console.log(`${countdownData.value[0]}小时 ${countdownData.value[1]}分钟 ${countdownData.value[2]}秒`);
      }
    }, 1000);
    console.log('启动倒计时！', countdownInterval);
  }
}
</script>

<template>
  <div class="relative leading-normal flex flex-wrap gap-[10px] md:grid md:grid-cols-3 md:gap-[15px]">
    <div class="sp-bg rounded-[8px] p-[15px] flex items-center w-full">
      <div class="w-1/3 pr-[10px]">
        <img class="w-full block" src="@/assets/images/new-version/dailyRank/daily-1.png" />
      </div>
      <div class="w-2/3">
        <div class="text-[#92CBFF] text-[20px] font-[500] text-left">{{ t('daily') }}</div>
        <div class="text-[#ffffff] text-[14px] font-[500] text-left mt-[15px] mb-[5px]">{{ t('contest_prize_pool') }}</div>
        <div class="text-[#1897FB] text-[28px] font-[600] rounded-[8px] bg-[#191F33] p-[8px]">{{ formatNumberWithCommas(props.info.total_prize, true) }} {{ props.info.total_prize_symbol }}</div>
      </div>
    </div>
    <div class="bg-[#191F33] rounded-[8px] p-[15px] flex flex-col justify-center w-full">
      <div class="text-[#AAC4ED] text-[16px] font-[500] text-center">{{ t('time_remaining') }}</div>
      <div class="flex justify-evenly text-center mt-[15px]">
        <div class="bg-[#222942] rounded-[8px] w-[80px] py-[15px]">
          <div class="text-[#FFFFFF] text-[18px] font-[700]">{{ countdownData[0] }}</div>
          <div class="text-[#AAC4ED] text-[14px] font-[400] mt-[5px]">{{ t('hours') }}</div>
        </div>
        <div class="bg-[#222942] rounded-[8px] w-[80px] py-[15px]">
          <div class="text-[#FFFFFF] text-[18px] font-[700]">{{ countdownData[1] }}</div>
          <div class="text-[#AAC4ED] text-[14px] font-[400] mt-[5px]">{{ t('minutes') }}</div>
        </div>
        <div class="bg-[#222942] rounded-[8px] w-[80px] py-[15px]">
          <div class="text-[#FFFFFF] text-[18px] font-[700]">{{ countdownData[2] }}</div>
          <div class="text-[#AAC4ED] text-[14px] font-[400] mt-[5px]">{{ t('seconds') }}</div>
        </div>
      </div>
    </div>
    <div class="bg-[#191F33] rounded-[8px] p-[15px] flex flex-col justify-center relative w-full">
      <img class="absolute left-0 top-0 w-[100px]" src="@/assets/images/new-version/dailyRank/daily-2.png" />
      <div v-if="props.info.yesterday_first_info.user_name">
        <div class="flex justify-center items-center">
          <img class="w-[34px] block mb-[5px]" src="@/assets/images/new-version/dailyRank/daily-3.png" />
          <div class="text-[#AAC4ED] text-[16px] font-[500] ml-[10px]">{{ t('last_champion') }}</div>
        </div>
        <div class="flex justify-center items-center mt-[15px]">
          <div class="w-[70px] h-[70px] rounded-full overflow-hidden">
            <img class="w-full h-full" :src="getHeaderImg(props.info.yesterday_first_info.profile_image_url)" />
          </div>
          <div class="ml-[10px]">
            <div class="text-[#FFFFFF] text-[16px] font-[700] text-left">{{ props.info.yesterday_first_info.user_name }}</div>
            <div class="text-[#AAC4ED] text-[16px] font-[400] text-left">{{ `${t('prize')}: ${formatNumberWithCommas(props.info.yesterday_first_info.prize_amount)} ${props.info.yesterday_first_info.prize_symbol} (${props.info.yesterday_first_info.prize_amount_ratio})` }}</div>
          </div>
        </div>
      </div>
      <div v-else class="text-[#AAC4ED] text-[16px] font-[400]">
        {{ t('no_data') }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.sp-bg {
  background: linear-gradient(95deg, #1793fb -12.24%, #191f33 106.22%);
}
</style>
