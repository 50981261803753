import axios from 'axios';
import serverConfig from './serverConfig';
import { getCookieAccessToken } from '../tools/CommonTool';
import { getFingerPrintInfo, clearUserCookies } from '../tools/CommonTool';
import router from '../../routes/index.js';
import { useLoginStore,useAppStore} from '@/store/store';

const httpManager = axios.create({
  baseURL: serverConfig.baseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  withCredentials: serverConfig.withCredentials
});
// 创建请求拦截
httpManager.interceptors.request.use(
  (config) => {
    const accessToken = getCookieAccessToken();
    if (serverConfig.useTokenAuthorization && accessToken !== undefined && accessToken.length > 0) {
      config.headers['Authorization'] = 'Bearer ' + accessToken; // 请求头携带 token
    }
    const fingerPrintInfo = getFingerPrintInfo();
    if (fingerPrintInfo !== undefined && fingerPrintInfo !== null) {
      config.headers['UUID'] = fingerPrintInfo;
    }
    let language = 'EN';
    let languageLocale = localStorage.getItem('locale');
    if (!languageLocale) {
      languageLocale = language;
    }
    if (languageLocale.toLowerCase().includes('ko')) {
      language = 'KO';
    } else if (languageLocale.toLowerCase().includes('zh')) {
      language = 'ZH';
    } else if (languageLocale.toLowerCase().includes('pt')) {
      language = 'PT';
    } else if (languageLocale.toLowerCase().includes('es')) {
      language = 'ES';
    } else if (languageLocale.toLowerCase().includes('tr')) {
      language = 'TR';
    } else if (languageLocale.toLowerCase().includes('sw')) {
      language = 'SW';
    }
    config.headers['Accept-Language'] = language;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 创建响应拦截
httpManager.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    // let message = '';
    console.log(error);
    if (error && error.response) {
      if (error.response.status === 401) {
        clearUserCookies();
        setTimeout(function () {
          if (router.currentRoute.value.path == '/') {
            window.sessionStorage.setItem('reloadLogin', 66)
            window.location.reload();
          } else {
            router.push('/');
            const loginStore = useLoginStore();
            loginStore.updateActType('login');
            loginStore.updateShow(true);
          }
        }, 1000);
        // ForceLogin();
        // setTimeout(function () {
        //   const loginStore = useLoginStore();
        //   loginStore.updateActType('login');
        //   loginStore.updateShow(true);
        //   const appStore = useAppStore();
        //   appStore.fpInit = false;
        //   clearUserCookies();
        // }, 1500);
      } else if (error.response.status === 500) {
        let language = 'EN';
        let languageLocale = localStorage.getItem('locale');
        if (!languageLocale) {
          languageLocale = language;
        }
        let message = 'The server is busy, please try again later. Thank you for your patience.';
        if (languageLocale.toLowerCase().includes('ko')) {
          message = '서버가 바쁩니다. 잠시 후 다시 시도해 주십시오. 인내심을 갖고 기다려 주셔서 감사합니다.';
        } else if (languageLocale.toLowerCase().includes('zh')) {
          message = '服務器忙碌中,請稍後再試,謝謝您的耐心等候。';
        } else if (languageLocale.toLowerCase().includes('pt')) {
          message = 'O servidor está ocupado, por favor, tente novamente mais tarde. Obrigado pela sua paciência.';
        } else if (languageLocale.toLowerCase().includes('es')) {
          message = 'El servidor está ocupado, por favor inténtelo de nuevo más tarde. Gracias por su paciencia.';
        } else if (languageLocale.toLowerCase().includes('tr')) {
          message = 'Sunucu meşgul, lütfen daha sonra tekrar deneyin. Sabrınız için teşekkürler.';
        }
        let myError = {
          response: {
            data: [message]
          }
        };
        return Promise.reject(myError);
      }else if (error.response.status === 403) {

        const appStore = useAppStore();
        clearUserCookies();
        appStore.clearUserInfo();
        return Promise.reject(error);
        
      }
      // switch (error.response.status) {
      //   case 302:
      //     message = "接口重定向了！";
      //     break;
      //   case 400:
      //     message = "参数不正确！";
      //     break;
      //   case 401:
      //     message = "您未登录，或者登录已经超时，请先登录！";
      //     break;
      //   case 403:
      //     message = "您没有权限操作！";
      //     break;
      //   case 404:
      //     message = `请求地址出错: ${error.response.config.url}`;
      //     break;
      //   case 408:
      //     message = "请求超时！";
      //     break;
      //   case 409:
      //     message = "系统已存在相同数据！";
      //     break;
      //   case 500:
      //     message = "服务器内部错误！";
      //     break;
      //   case 501:
      //     message = "服务未实现！";
      //     break;
      //   case 502:
      //     message = "网关错误！";
      //     break;
      //   case 503:
      //     message = "服务不可用！";
      //     break;
      //   case 504:
      //     message = "服务暂时无法访问，请稍后再试！";
      //     break;
      //   case 505:
      //     message = "HTTP 版本不受支持！";
      //     break;
      //   default:
      //     message = "异常问题，请联系管理员！";
      //     break;
      // }
    } else if (error.code == 'ERR_NETWORK' || error.code == 'ERR_BAD_RESPONSE' || error.code == 'ECONNABORTED' || error.code == 'ERR_CONNECTION_CLOSED') {
      let language = 'EN';
      let languageLocale = localStorage.getItem('locale');
      if (!languageLocale) {
        languageLocale = language;
      }
      let message = 'The server is busy, please try again later. Thank you for your patience.';
      if (languageLocale.toLowerCase().includes('ko')) {
        message = '서버가 바쁩니다. 잠시 후 다시 시도해 주십시오. 인내심을 갖고 기다려 주셔서 감사합니다.';
      } else if (languageLocale.toLowerCase().includes('zh')) {
        message = '服務器忙碌中,請稍後再試,謝謝您的耐心等候。';
      } else if (languageLocale.toLowerCase().includes('pt')) {
        message = 'O servidor está ocupado, por favor, tente novamente mais tarde. Obrigado pela sua paciência.';
      } else if (languageLocale.toLowerCase().includes('es')) {
        message = 'El servidor está ocupado, por favor inténtelo de nuevo más tarde. Gracias por su paciencia.';
      } else if (languageLocale.toLowerCase().includes('tr')) {
        message = 'Sunucu meşgul, lütfen daha sonra tekrar deneyin. Sabrınız için teşekkürler';
      }
      let myError = {
        response: {
          data: [message]
        }
      };
      return Promise.reject(myError);
    }
    return Promise.reject(error);
  }
);

export default httpManager;
