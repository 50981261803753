<script setup>
import { ref, watch, computed, onMounted, onUnmounted, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import { useRoute, useRouter } from 'vue-router';
import closeIcon from '@/assets/images/common/common-floating-close.svg';
import closeIcon2 from '@/assets/images/common/common-floating-close-2.svg';
import { getCookieAccessToken } from '@/assets/tools/CommonTool';
import { getFloatButtonConfigsService, floatButtonConfigsService } from '@/assets/network/service/home';

const emit = defineEmits(['needLogin','deposit']);

const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
    join_us: t('join_us'),
    join: t('join')
};

const router = useRouter();
const route = useRoute();

const floatConfigs = ref([]);
const operateClose = ref([false,false]);
const expend = ref([false,false]);
const routeCanShow = ref(route.path !== '/gameDetail' && route.path !== '/download' && route.path !== '/download1');
const isShow = computed(() => {
    if(matchSM.value && route.path !== '/') {
        return false;
    }
    if(!routeCanShow.value) {
        return false;
    }
    if(floatConfigs.value.length === 0) {
        return false;
    }
    return true;
});
const intervalIds = ref([null,null]);
const autToCloseTime = 8;
const countdownTime = ref([autToCloseTime,autToCloseTime]);

const handleLookDetailClick = (index) => {
    expend.value[index] = true;

    intervalIds.value[index] = setInterval(() => {
      if (countdownTime.value[index] <= 1) {
        clearInterval(intervalIds.value[index]);
        intervalIds.value[index] = null;
        countdownTime.value[index] = autToCloseTime;
        expend.value[index] = false;
      } else {
        countdownTime.value[index] -= 1;
      }
    }, 1000);
};
const handleJoinClick = (index) => {
    if(intervalIds.value[index]) {
        clearInterval(intervalIds.value[index]);
        intervalIds.value[index] = null;
    }

    handleUploadEvent(floatConfigs.value[index].id);

    const token = getCookieAccessToken();
    if(token !== undefined && token !== null && token.length > 0) {
        if(floatConfigs.value[index].link_type === 'Internal') {
            if(floatConfigs.value[index].link.includes('deposit')) {
                emit('deposit');
            } else if(floatConfigs.value[index].link.includes('login')) {
                emit('needLogin',true);
            } else if(floatConfigs.value[index].link.includes('register')) {
                emit('needLogin',false);
            } else {
                router.push(floatConfigs.value[index].link);  
            }
        } else {
            window.open(floatConfigs.value[index].link, '_blank');
        }
    } else {
        emit('needLogin',true);
    }
};
const handleCloseFloatingClick = (index) => {
    operateClose.value[index] = true;
};
const handleCloseExpendClick = (index) => {
    expend.value[index] = false;

    if(intervalIds.value[index]) {
        clearInterval(intervalIds.value[index]);
        intervalIds.value[index] = null;
        countdownTime.value[index]= autToCloseTime;
    }
};

const handleFetchFloatButtonConfigs = async () => {
    try {
        const response = await getFloatButtonConfigsService();
        if(response.data.code == 200) {
            floatConfigs.value = response.data.data;
        }
    } catch (error) {
        console.log('handleFetchFloatButtonConfigs error:',error);
    }
};
const handleUploadEvent = async (id) => {
    try {
        await floatButtonConfigsService({ id: id });
    } catch (error) {
        console.log('handleUploadEvent error:',error);
    }
};

const resetStatus = () => {
    floatConfigs.value = [];
    operateClose.value = [false,false];
    expend.value = [false,false];
    intervalIds.value = [null,null];
    countdownTime.value = [autToCloseTime,autToCloseTime];
};

watch(() => route.path, (newValue) => {
    resetStatus();

    if(newValue !== '/gameDetail' && newValue !== '/download' && newValue !== '/download1') {
        routeCanShow.value = true;
    }else {
        routeCanShow.value = false;
    }

    handleFetchFloatButtonConfigs();
});
onMounted(() => {
    handleFetchFloatButtonConfigs();
});
onUnmounted(() => {
    for (const [key, value] of Object.entries(intervalIds.value)) {
        if(value) {
            clearInterval(value);
            intervalIds.value[key] = null;
        }
    }
});

</script>

<template>
    <Teleport v-if="isShow" to="body">
        <div class="activity-floating-container-1" :class="matchSM ? 'matchSM' : ''">
            <Transition name="scale">
                <div v-if="!operateClose[0] && !expend[0]" class="bg-content-activity-floating" :class="matchSM ? 'matchSM' : ''" :style="{ top: matchSM ? '2rem' : '0' }">
                    <div class="container-top-content" :class="matchSM ? 'matchSM' : ''">
                        <img class="container-top-content-img" :style="{ cursor: 'pointer' }" :src="floatConfigs[0].image_url" @click="handleLookDetailClick(0)"/>
                        <button class="close-button" :class="matchSM ? 'matchSM' : ''" @click="handleCloseFloatingClick(0)">
                            <img class="close-button-img" :class="matchSM ? 'matchSM' : ''" :src="closeIcon" />
                        </button>
                    </div>
                    <div class="container-bottom-content" :class="matchSM ? 'matchSM' : ''">{{ floatConfigs[0].title }}</div>
                </div>
            </Transition>
            <Transition name="slide-fade">
                <div v-if="!operateClose[0] && expend[0]" class="bg-content-activity-floating-expend" :class="matchSM ? 'matchSM' : ''">
                    <div class="container-top-content" :class="matchSM ? 'matchSM' : ''">
                        <img class="container-top-content-img" :class="matchSM ? 'matchSM' : ''" :src="floatConfigs[0].image_url"/>
                    </div>
                    <div class="text-content-expend" :class="matchSM ? 'matchSM' : ''">
                        <div class="text-content-expend-title">{{ textData.join_us }}</div>
                        <div class="text-content-expend-desc">{{ floatConfigs[0].content }}</div>
                    </div>
                    <button class="join-button" @click="handleJoinClick(0)">{{ textData.join }}</button>
                    <button class="close-button-expend" :class="matchSM ? 'matchSM' : ''" @click="handleCloseExpendClick(0)">
                        <img class="close-button-expend-img" :src="closeIcon2" />
                    </button>
                </div>
            </Transition>
        </div>

        <div v-if="floatConfigs.length >= 2" class="activity-floating-container-2" :class="matchSM ? 'matchSM' : ''" :style="{ top: operateClose[0] ? (matchSM ? '3.75rem' : '5.625rem') : (matchSM ? '11.625rem' : '12.55rem') }">
            <Transition name="scale">
                <div v-if="!operateClose[1] && !expend[1]" class="bg-content-activity-floating" :class="matchSM ? 'matchSM' : ''" :style="{ top: operateClose[0] ? (matchSM ? '2rem' : '0') : (matchSM ? '1.125rem' : '0')}">
                    <div class="container-top-content" :class="matchSM ? 'matchSM' : ''">
                        <img class="container-top-content-img" :style="{ cursor: 'pointer' }" :src="floatConfigs[1].image_url" @click="handleLookDetailClick(1)"/>
                        <button class="close-button" :class="matchSM ? 'matchSM' : ''" @click="handleCloseFloatingClick(1)">
                            <img class="close-button-img" :class="matchSM ? 'matchSM' : ''" :src="closeIcon" />
                        </button>
                    </div>
                    <div class="container-bottom-content" :class="matchSM ? 'matchSM' : ''">{{ floatConfigs[1].title }}</div>
                </div>
            </Transition>
            <Transition name="slide-fade">
                <div v-if="!operateClose[1] && expend[1]" class="bg-content-activity-floating-expend" :class="matchSM ? 'matchSM' : ''">
                    <div class="container-top-content" :class="matchSM ? 'matchSM' : ''">
                        <img class="container-top-content-img" :class="matchSM ? 'matchSM' : ''" :src="floatConfigs[1].image_url"/>
                    </div>
                    <div class="text-content-expend" :class="matchSM ? 'matchSM' : ''">
                        <div class="text-content-expend-title">{{ textData.join_us }}</div>
                        <div class="text-content-expend-desc">{{ floatConfigs[1].content }}</div>
                    </div>
                    <button class="join-button" @click="handleJoinClick(1)">{{ textData.join }}</button>
                    <button class="close-button-expend" :class="matchSM ? 'matchSM' : ''" @click="handleCloseExpendClick(1)">
                        <img class="close-button-expend-img" :src="closeIcon2" />
                    </button>
                </div>
            </Transition>
        </div>
    </Teleport>
</template>

<style scoped>
.activity-floating-container-1 {
    position: fixed;
    top: 5.625rem;
    right: 0;
    z-index: 2;
}
.activity-floating-container-1.matchSM {
    top: 3.75rem;
}
.activity-floating-container-2 {
    position: fixed;
    top: 12.55rem;
    right: 0;
    z-index: 2;
    transition: all 0.25s ease-out;
}
.activity-floating-container-2.matchSM {
    top: 11.625rem;
}
.bg-content-activity-floating {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 8.375rem;
}
.bg-content-activity-floating.matchSM {
    width: 5.25rem;
}
.container-top-content {
    position: relative;
    background-color: #3E4B79;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
    border-radius: 4rem;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-top-content.matchSM {
    width: 3.375rem;
    height: 3.375rem;
    min-width: 3.375rem;
    min-height: 3.375rem;
}
.container-top-content-img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.close-button {
    position: absolute;
    top: -0.3125rem;
    right: -1.25rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    text-transform: none;
    border: none;
    cursor: pointer;
}
.close-button.matchSM {
    width: 1.25rem;
    height: 1.25rem;
    top: -1.25rem;
    right: -0.625rem;
}
.close-button:hover {
    opacity: 0.8;
}
.close-button-img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
}
.close-button-img.matchSM {
    width: 1.25rem;
    height: 1.25rem;
}
.container-bottom-content {
    background-color: #3E4B79;
    min-height: 1.25rem;
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding: 0 0.6875rem;
    border-radius: 1.25rem;
}
.container-bottom-content.matchSM {
    font-size: 0.75rem;
    padding: 0 0.5625rem;
    min-height: 1.0625rem;
}

.bg-content-activity-floating-expend {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #222942;
    border-radius: 0.5rem;
    padding: 1rem 2.25rem 1rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 22.1875rem;
    max-width: 22.1875rem;
}
.bg-content-activity-floating-expend.matchSM {
    width: calc(100vw - 2.4375rem);
    max-width: calc(100vw - 2.4375rem);
    border-radius: 0;
    padding: 0.5rem 1.4375rem 0.5rem 1rem;
    min-height: 5.75rem;
}
.text-content-expend {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}
.text-content-expend-title {
    width: 100%;
    color: #FFFFFF;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.text-content-expend-desc {
    width: 100%;
    color: #80A4DB;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}
.join-button {
    border: none;
    height: 2.5rem;
    background-color: #3AA1FF;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(17, 132, 250, 0.40), 0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset, 0px 0px 12px 0px #0D52B2 inset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.6875rem;
    color: #121829;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: justify;
    cursor: pointer;
}
.join-button:hover {
    opacity: 0.8;
}
.close-button-expend {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 2.125rem;
    height: 2.125rem;
    cursor: pointer;
}
.close-button-expend.matchSM {
    width: 2rem;
    height: 2rem;
}
.close-button-expend:hover {
    opacity: 0.8;
}
.close-button-expend-img {
    width: 1.5rem;
    height: 1.5rem;
}


/* 动画 */
.scale-enter-active {
  transition: all 0.25s ease-out 0.25s;
}
.scale-leave-active {
  transition: all 0.15s ease-out;
}
.scale-enter-from,
.scale-leave-to {
  transform: scale(0);
}

.slide-fade-enter-active {
  transition: all 0.25s ease-out 0.25s;
}
.slide-fade-leave-active {
  transition: all 0.15s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>