import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import serverConfig from '../assets/network/serverConfig';

const httpManager = axios.create({
  baseURL: serverConfig.baseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  withCredentials: serverConfig.withCredentials
});
// import React from 'react';
// import { BrowserRouter,Route, Routes } from "react-router-dom";
// import RoutePath from '../tools/RoutePath';
// import Home from "../pages/Home";
// import Favorite from '../pages/Favorite';
// import Recent from '../pages/Recent';
// import LoginAndRegister from '../pages/LoginAndRegister';
// import Wallet from '../pages/Wallet';
// import Deposit from '../pages/Deposit';
// import Withdraw from '../pages/Withdraw';
// import Game from '../pages/Game';
// import GameMobile from '../pages/GameMobile';
// import Profile from '../pages/Profile';
// import Poker from '../pages/Poker';
// import TokenRecord from '../pages/TokenRecord';
// import FAQ from '../pages/FAQ';
// import Slots from '../pages/Slots';
// import HouseGames from '../pages/HouseGames';
// import HelpCenter from '../pages/HelpCenter';
// import Referral from '../pages/Referral';
// import HelpCenterDesc from '../pages/HelpCenterDesc';
// import LiveCasino from '../pages/LiveCasino';
// import SDGameDice from '../pages/SDGameDice';
// import CasinoList from '../pages/CasinoList';
// import SDGameLimbo from '../pages/SDGameLimbo';
import test2 from '../pages/myTest.vue';
import home from '../pages/home/homeIndex.vue';
import login from '../pages/login/myLogin.vue';
import allSlots from '@/pages/slots/allSlots.vue';
import popularSlots from '@/pages/slots/popularSlots.vue';
import newSlots from '@/pages/slots/newSlots.vue';
import hotSlots from '@/pages/slots/hotSlots.vue';
import favorites from '@/pages/slots/favorites.vue';
import recent from '@/pages/slots/recent.vue';
import liveCasino from '@/pages/slots/liveCasino.vue';
import baccarat from '@/pages/slots/baccarat.vue';
import providers from '@/pages/slots/providers.vue';
import providersList from '@/pages/slots/providerList.vue';

import gameDetail from '@/pages/game_detail/gameDetail.vue';
import transaction from '@/pages/profile/transaction.vue';
import setting from '@/pages/profile/setting.vue';
import help_center from '@/pages/help_center/index.vue';
import referral from '@/pages/referral/index.vue';
import myMenus from '@/pages/menus/myMenus.vue';
import bannerDes from '@/components/banner/bannerDesc.vue';
import term_of_service from '@/pages/term_of_service/term_of_service.vue';
import term_of_conditions from '@/pages/term_of_conditions/term_of_conditions.vue';
import privacy_policy from '@/pages/privacy_policy/privacy_policy.vue';
import notificationCenter from '@/pages/notificationCenter/notificationCenter.vue';
import vipClub from '@/pages/vip/vipClub.vue';
import affiliateProgram from '@/pages/affiliate_program/affiliateProgram.vue';
import promotionList from '@/pages/promotion/promotion-list.vue';
import promotionDetail from '@/pages/promotion/promotion-detail.vue';
import dailyContest from '@/pages/promotion/daily-contest/index.vue';
import myBeats from '@/pages/profile/myBeats.vue';
import depositResult from '@/pages/symbol/depositResult.vue';
import downloadPage from '@/pages/pwa/downloadPage.vue';
import downloadPage1 from '@/pages/pwa/downloadPage1.vue';
import LayoutPage from '@/pages/profile/layoutPage';
import PageDeposit from '@/pages/wallet/PageDeposit';
import PageWithdraw from '@/pages/wallet/PageWithdraw';
import balancePage from '@/pages/profile/balancePage';
import { userRetentionService } from '@/assets/network/service/user';
import { getCookieAccessToken } from '@/assets/tools/CommonTool';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'Home', component: home },
    { path: '/promotion', name: 'promotionList', component: promotionList },
    { path: '/promotionDetail', name: 'promotionDetail', component: promotionDetail },
    { path: '/dailyContest', name: 'dailyContest', component: dailyContest },
    { path: '/login', name: 'login', component: login },
    { path: '/slots/allSlots', name: 'allSlots', component: allSlots },
    { path: '/slots/popularSlots', name: 'popularSlots', component: popularSlots },
    { path: '/slots/newSlots', name: 'newSlots', component: newSlots },
    { path: '/slots/hotSlots', name: 'hotSlots', component: hotSlots },
    { path: '/favorites', name: 'favorites', component: favorites },
    { path: '/recent', name: 'recent', component: recent },
    { path: '/liveCasino', name: 'liveCasino', component: liveCasino },
    { path: '/gameDetail', name: 'gameDetail', component: gameDetail },
    // { path: '/profile/transaction', name: 'transaction', component: transaction },
    // { path: '/profile/setting', name: 'setting', component: setting },
    { path: '/helpCenter', name: 'help_center', component: help_center },
    { path: '/referral', name: 'referral', component: referral },
    { path: '/test', name: 'test', component: test2 },
    { path: '/m', name: 'm', component: myMenus },
    { path: '/banner', name: 'banner', component: bannerDes },
    { path: '/term_of_service', name: 'term_of_service', component: term_of_service },
    { path: '/privacy_policy', name: 'privacy_policy', component: privacy_policy },
    { path: '/baccarat', name: 'baccarat', component: baccarat },
    { path: '/providers', name: 'providers', component: providers },
    { path: '/providers-list', name: 'providersList', component: providersList },
    { path: '/notification-center', name: 'notificationCenter', component: notificationCenter },
    { path: '/vip-club', name: 'vipClub', component: vipClub },
    { path: '/affiliate-program', name: 'affiliateProgram', component: affiliateProgram },
    // { path: '/profile/myBeats', name: 'myBeats', component: myBeats },
    { path: '/term_of_conditions', name: 'term_of_conditions', component: term_of_conditions },
    { path: '/depositResult', name: 'depositResult', component: depositResult },
    { path: '/download', name: 'download', component: downloadPage },
    { path: '/download1', name: 'download1', component: downloadPage1 },
    {
      path: '/profile',
      name: 'profile',
      component: LayoutPage,
      children: [
        {
          path: 'setting',
          name: 'setting',
          component: setting
        },
        {
          path: 'transaction',
          name: 'transaction',
          component: transaction
        },
        {
          path: 'myBeats',
          name: 'myBeats',
          component: myBeats
        },
        {
          path: 'deposit',
          name: 'deposit',
          component: PageDeposit
        },
        {
          path: 'withdraw',
          name: 'withdraw',
          component: PageWithdraw
        },
        {
          path: 'balance',
          name: 'balance',
          component: balancePage
        },
        {
          path: 'vip',
          name: 'vip',
          component: vipClub
        }
      ]
    }
  ],
  scrollBehavior() {
    return { top: 0 };
  }
});
router.beforeEach(() => {
  const token = getCookieAccessToken();
  if (token !== undefined && token !== null && token.length > 0) {
    userRetentionService();
  }
})
// router.beforeEach((to, from, next) => {
//   let local = localStorage.getItem('locale')
//   // 查询语言是否存在
//   if (local) {
//     next();
//   } else {
//     getLocalPre(next,{})
//   }
// });
export const getLocalPre = (callBack, params) => {
  getLocal()
    .then((response) => {
      let temp = response.data.data.language.toLowerCase();
      if (!temp || temp == 'zh') {
        temp = 'en';
      }
      localStorage.setItem('locale', temp);
      // if(response.data.data.language){
      //     localStorage.setItem('locale', response.data.data.language.toLowerCase())
      // }
      callBack(params);
    })
    .catch((error) => {
      console.log(error);
      localStorage.setItem('locale', 'en');
      callBack(params);
    });
};
export const getLocal = () => {
  return httpManager({
    url: '/playeruser/get_ip_language/',
    method: 'get'
  });
};

export default router;
