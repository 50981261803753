<script setup>
// 导入
import { ref, onBeforeMount } from 'vue';
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/store/store';
import { getDailyContestInfo } from '@/assets/network/service/home';
import { getYesterdayDailyContestInfo } from '@/assets/network/service/home';
import PublicInfoBar from './components/PublicInfoBar.vue';
import UserInfoBar from './components/UserInfoBar.vue';
import BetRankList from './components/BetRankList.vue';
import HistoryBetRankList from './components/HistoryBetRankList.vue';
import wgDialog from '@/components/common/wgDialog.vue';

// 传参
// 属性
const { t } = useI18n();
const appStore = useAppStore();

const todayData = ref(null);
const yesterdayData = ref(null);
const isLoading = ref(false);

const showHistory = ref(false);
// 计算属性
// 生命周期
onBeforeMount(() => {
  getDailyContestData();
});
// 方法
const loginAfter = () => {
  getDailyContestData();
};

const getDailyContestData = async () => {
  isLoading.value = true;
  const res = await getDailyContestInfo();
  todayData.value = res.data.data;
  const res2 = await getYesterdayDailyContestInfo();
  yesterdayData.value = res2.data.data;
  isLoading.value = false;
};

const openHistoryDialog = () => {
  showHistory.value = true;
};

const closeHistoryDialog = () => {
  showHistory.value = false;
};
</script>

<template>
  <div class="root_div home" @click="isGameSearch = false">
    <menus @menuChange="menuChange"></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <myHeader @loginAfter="loginAfter"></myHeader>
      <div class="main-content">
        <div class="body">
          <template v-if="todayData && todayData.is_open">
            <div class="pt-[10px] md:pt-[30px]">
              <PublicInfoBar :info="todayData" />
            </div>
            <div v-if="appStore.isLogin" class="pt-[10px] md:pt-[30px]">
              <UserInfoBar :info="todayData" />
            </div>
            <div class="pt-[10px] md:pt-[30px]">
              <BetRankList :info="todayData" :openHistory="openHistoryDialog" />
            </div>
          </template>
          <template v-else-if="todayData && !todayData.is_open">
            <div class="flex flex-col justify-center items-center gap-[40px] h-[60vh]">
              <img class="w-[300px] block" src="@/assets/images/home/web_logo.svg" alt="" />
              <div class="text-[#ffffff] text-[22px] font-[600]">{{ t('unopened_tips') }}</div>
            </div>
          </template>
        </div>
      </div>
      <myFooter :isHomeFooter="true"></myFooter>
    </div>
    <wgDialog :show="showHistory">
      <HistoryBetRankList :info="yesterdayData" :closeHistory="closeHistoryDialog" />
    </wgDialog>
  </div>
</template>

<style scoped></style>
