<script setup>
// 导入
import { useI18n } from 'vue-i18n';
import { formatNumberWithCommas, formatRankText, isMobile } from '@/assets/tools/CommonTool';
import { getHeaderImg } from '@/assets/tools/avatar.js';

const { t } = useI18n();
// 传参
const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  }
});
// 属性
// 计算属性
// 生命周期
// 方法
</script>

<template>
  <div class="relative leading-normal bg-[#191F33] rounded-[8px] p-[20px]">
    <div class="flex flex-col justify-start items-start md:flex-row md:justify-between md:items-center">
      <div class="flex items-center gap-[15px]">
        <div v-if="!isMobile()" class="w-[70px] h-[70px] rounded-full overflow-hidden">
          <img class="w-full h-full" :src="getHeaderImg(props.info.my_info.my_profile_image_url)" />
        </div>
        <div class="text-[#FFFFFF] text-[20px] font-[700]">{{ props.info.my_info.my_user_name }}</div>
      </div>
      <div class="text-[#AAC4ED] text-[18px] font-[400]">
        {{ `${t('wager_to_reach')}: ` }}
        <span class="text-[#FFFFFF] text-[18px] font-[400]">{{ `€ ${formatNumberWithCommas(props.info.my_info.reach_bet_amount)}` }}</span>
      </div>
    </div>
    <div class="flex flex-col gap-[10px] md:flex-row md:justify-between md:items-center mt-[15px] bg-[#121829] rounded-[8px] p-[20px]">
      <template v-if="!isMobile()">
        <div class="flex items-center w-1/2">
          <div class="w-[36px]">
            <img class="w-full" src="@/assets/images/new-version/dailyRank/daily_positon.svg" />
          </div>
          <div class="ml-[10px] text-left">
            <div class="text-[#FFFFFF] text-[12px] font-[700]">{{ t('my_position').toLocaleUpperCase() }}</div>
            <div class="text-[#FFFFFF] text-[18px] font-[400]">{{ formatRankText(props.info.my_info.rank_num) }}</div>
          </div>
        </div>
        <div class="flex items-center w-1/2">
          <div class="w-[26px]">
            <img class="w-full" src="@/assets/images/profile/profile/ico_profile_2.svg" />
          </div>
          <div class="ml-[10px] text-left">
            <div class="text-[#FFFFFF] text-[12px] font-[700]">{{ t('wagered').toLocaleUpperCase() }}</div>
            <div class="text-[#FFFFFF] text-[18px] font-[400]">{{ `€ ${formatNumberWithCommas(props.info.my_info.my_bet_amount)}` }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-between items-start">
          <div class="flex items-center ml-[-5px]">
            <img class="w-[36px]" src="@/assets/images/new-version/dailyRank/daily_positon.svg" />
            <div class="text-[#FFFFFF] text-[12px] font-[700]">{{ t('my_position').toLocaleUpperCase() }}</div>
          </div>
          <div class="flex items-cente">
            <div class="text-[#FFFFFF] text-[18px] font-[400]">{{ formatRankText(props.info.my_info.rank_num) }}</div>
          </div>
        </div>
        <div class="flex justify-between items-start">
          <div class="flex items-center">
            <img class="w-[26px]" src="@/assets/images/profile/profile/ico_profile_2.svg" />
            <div class="ml-[5px] text-[#FFFFFF] text-[12px] font-[700]">{{ t('wagered').toLocaleUpperCase() }}</div>
          </div>
          <div class="flex items-center">
            <div class="text-[#FFFFFF] text-[18px] font-[400]">{{ `€ ${formatNumberWithCommas(props.info.my_info.my_bet_amount)}` }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped></style>
