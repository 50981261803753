<script setup>
// 导入
import { useI18n } from 'vue-i18n';
import { formatNumberWithCommas, formatRankText } from '@/assets/tools/CommonTool';
import { getHeaderImg } from '@/assets/tools/avatar.js';
import rank1 from '@/assets/images/new-version/dailyRank/rank-1.png';
import rank2 from '@/assets/images/new-version/dailyRank/rank-2.png';
import rank3 from '@/assets/images/new-version/dailyRank/rank-3.png';

const { t } = useI18n();
// 传参
const props = defineProps({
  info: {
    type: Object,
    default: () => {}
  },
  closeHistory: {
    type: Function,
    default: () => {}
  }
});
// 属性
// const tempDataList = [
//   {
//     rank_num: 1,
//     user_id: 1561,
//     bet_amount: 40.0,
//     user_name: 'fire',
//     profile_image_url: 'Resources://avatars/Ellipse 141',
//     prize_amount: '50.0000',
//     prize_symbol: 'BRL',
//     prize_amount_ratio: '50.00%'
//   },
//   {
//     rank_num: 2,
//     user_id: 1390,
//     bet_amount: 20.0,
//     user_name: 'TT8000850365',
//     profile_image_url: 'Resources://avatars/Ellipse 141',
//     prize_amount: '10.0000',
//     prize_symbol: 'BRL',
//     prize_amount_ratio: '10.00%'
//   },
//   {
//     rank_num: 3,
//     user_id: 1390,
//     bet_amount: 20.0,
//     user_name: 'TT8000850365',
//     profile_image_url: 'Resources://avatars/Ellipse 141',
//     prize_amount: '10.0000',
//     prize_symbol: 'BRL',
//     prize_amount_ratio: '10.00%'
//   },
//   {
//     rank_num: 4,
//     user_id: 1390,
//     bet_amount: 20.0,
//     user_name: 'TT8000850365',
//     profile_image_url: 'Resources://avatars/Ellipse 141',
//     prize_amount: '10.0000',
//     prize_symbol: 'BRL',
//     prize_amount_ratio: '10.00%'
//   }
// ];
// 计算属性
// 生命周期
// 方法
const clickCloseHistory = () => {
  props.closeHistory();
};
</script>

<template>
  <div class="relative leading-normal w-[80vw] md:w-[920px] bg-[#121829] rounded-[12px] p-[20px] text-center">
    <div class="flex justify-between items-center">
      <div class="flex items-center gap-[10px]">
        <div class="text-[#ffffff] text-[12px] font-[700]">{{ t('history').toLocaleUpperCase() }}</div>
        <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ props.info.date_key }}</div>
      </div>
      <div class="pl-[10px] py-[4px] flex justify-center items-center cursor-pointer" @click="clickCloseHistory">
        <el-icon class="text-[#FFFFFF] text-[20px]"><Close /></el-icon>
      </div>
    </div>
    <div class="flex flex-col gap-[5px] mt-[15px]">
      <div class="flex items-center bg-[#191F33] px-[10px] h-[40px]">
        <div class="md:w-[16%] w-[20%] text-[#80A4DB] text-[12px] font-[700] text-left">{{ t('ranking').toLocaleUpperCase() }}</div>
        <div class="md:w-[28%] w-[50%] text-[#80A4DB] text-[12px] font-[700] md:text-left text-center">{{ t('player').toLocaleUpperCase() }}</div>
        <div class="md:w-[28%] w-0 overflow-hidden text-[#80A4DB] text-[12px] font-[700] md:text-left text-center">{{ t('wagered').toLocaleUpperCase() }}</div>
        <div class="md:w-[28%] w-[30%] text-[#80A4DB] text-[12px] font-[700] md:text-left text-right">{{ t('prize').toLocaleUpperCase() }}</div>
      </div>
      <template v-if="props.info.rank_info && props.info.rank_info.length > 0">
        <div class="flex items-center bg-[#191F33] p-[10px]" v-for="(item, index) in props.info.rank_info" :key="index">
          <!-- <template v-if="tempDataList && tempDataList.length > 0">
        <div class="flex items-center bg-[#191F33] px-[10px] h-[40px]" v-for="(item, index) in tempDataList" :key="index"> -->
          <div class="md:w-[16%] w-[20%] text-[#FFFFFF] text-[14px] font-[400] text-left flex items-center">
            <img v-if="item.rank_num == 1" class="w-[18px] mr-[5px]" :src="rank1" />
            <img v-else-if="item.rank_num == 2" class="w-[18px] mr-[5px]" :src="rank2" />
            <img v-else-if="item.rank_num == 3" class="w-[18px] mr-[5px]" :src="rank3" />
            {{ formatRankText(item.rank_num) }}
          </div>
          <div class="md:w-[28%] w-[50%] text-[#FFFFFF] text-[14px] font-[400] text-left flex justify-center md:justify-start items-center">
            <img class="w-[18px] mr-[5px]" :src="getHeaderImg(item.profile_image_url)" />
            {{ item.user_name }}
          </div>
          <div class="md:w-[28%] w-0 overflow-hidden text-[#AAC4ED] text-[14px] font-[400] text-left">
            {{ `${formatNumberWithCommas(item.bet_amount)} ${item.prize_symbol}` }}
          </div>
          <div class="md:w-[28%] w-[30%] text-[#AAC4ED] text-[14px] font-[400] md:text-left text-right">
            {{ `${formatNumberWithCommas(item.prize_amount)} ${item.prize_symbol}` }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-[#80A4DB] text-[18px] p-[20px]">
          {{ t('no_data') }}
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped></style>
